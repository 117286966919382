

.main-container {
  width: 100%;
  height: 92vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  background-color: #A0E9FF;
}

.main-container.home {
  height: auto;
  padding-top: 5px;
}

.main-container .form-container h1 {
  margin-bottom: 8vh;
}
.main-container .form-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  min-height: 50%;
  max-height: 95%;
  background-color: white;
  padding: 15px;
  border-radius: 10px;

  
}

.molas-file {
  width: 150px;
  padding: 1.5rem;
  display: flex;
  margin-top: 1rem;
  border: 1px dashed grey;
  flex-direction: column;
}

.molas-file.simple {
  padding: 0.8rem;
}

.molas-file label {
  font-size: 12px;

}

.molas-file__form {
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.molas-file__form  p{
  font-size: 10px;
  font-style: italic;
  
}

.molas-file__form input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100% !important;
  height: 100%;
  z-index: 2;
  opacity: 0;
  outline: none;
  
}


.modifiers-content {
  width: 100%;
  margin-top: 0.5;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.modifiers-content .item {
  margin-top: 0.5em;
  width: 100%;
  display: flex;
  flex-direction: column;

}
.modifiers-content .item .options{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.modifiers-content .item .options .active{
  color: green !important;
  border-color: green !important;
}
.option-container {
  width: 100%;
  height: 350px;
  overflow-y: auto;
  overflow-x: hidden;
}
.variations-content {
  width: 100%;
  margin-top: 0.5em;
  margin-bottom: 1em;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  overflow-y: auto;
  max-height: 125px;
}

.variations-content__items {
  margin-left: 15px !important;
  margin-top: 10px !important;
}
.variations-content__items:first-child {
  margin-left: 0;
}

.variations-content__items.select {
  background-color: green;
  color: white;
  font-weight: bolder;
}
.active {
  color: green !important;
  border-color: green !important;
}


.order-item-container {
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0px;
  min-height: 350px;
  max-height: 550px;
  margin-top: 5px;
  width: 100%;
  margin-bottom: 5px;
}

.order-item-container .top-item{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.order-item-container .secondary-item {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.order-item-container .shadow {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
}
.order-bottom-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 225px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 12px;
  align-items: center;


}

.order-bottom-container__footer {
  width: 100%;
  text-align: right;
}
.order-bottom-container__footer span {
  font-size: 12px;
}
.payment-info {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.payment-info__item {
  margin: 5px;
}