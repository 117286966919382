
#layout-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;

  

}
.auth-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;




}

#layout-container .header-container {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #52FFEE;
  z-index: 2;
  box-shadow: rgba(240, 46, 169, 0.282) 0px 5px, rgba(240, 46, 169, 0.19) 0px 10px, rgba(240, 46, 169, 0.108) 0px 15px, rgba(240, 46, 170, 0.1) 0px 20px, rgba(240, 46, 169, 0.055) 0px 25px;
}

#layout-container .header-container .logo{
  margin-left: 5vw;
  display: flex;
  align-items: center;

}

#layout-container .header-container .action-right{
  margin-right: 2vw;
}

#layout-container .container {
  background-color: #52FFEE;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: visible;
}

.auth-container.histories {
  width: 100%;
  overflow-y: auto;
}
.auth-container.warehouse .input{
  margin-top: 5px;
  margin-bottom: 2px;
}
.auth-container.warehouse .search-advance{
  position: relative;
  width: 100%;
}
.auth-container.warehouse .search-advance__active{
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 5;
  width: 100%;
  min-height: 125px;
  overflow-y: auto;
  max-height: 200px;
  background-color: #efefef;
}

.auth-container__type {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-bottom: 1px solid black;
  padding-bottom: 5px;

}
.auth-container__type.bottom {
  margin-top: 0.5em;
}

.auth-container__info {
  width: 100%;
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}