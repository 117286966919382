body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
/* 
$primary: #cff29b;
$pink: #FFC0D9;
$blue: #A0E9FF;
$secondary: #52FFEE;

$land: #964b00;
$bronze: #CD7F32;
$silver: #C0C0C0;
$gold: #FFD700;
$platinum: #E5E4E2; */
